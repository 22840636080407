/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 101번째 로그에서는 은퇴를 은퇴한 귀도, 공유오피스 집무실, 라이트세일 컨테이너, M1 칩 등 등에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "귀도 반 로썸 은퇴를 은퇴하다"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/gvanrossum/status/1326932991566700549"
  }, "공식 트윗")), "\n"), "\n", React.createElement(_components.h3, null, "집무실"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.jibmusil.com/"
  }, "공식 홈페이지")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.fastfive.co.kr/persona_ffpass"
  }, "패파 패스")), "\n"), "\n"), "\n", React.createElement(_components.h3, null, "라이트세일 컨테이너"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/blogs/korea/lightsail-containers-an-easy-way-to-run-your-containers-in-the-cloud/"
  }, "블로그 공지와 튜토리얼(우리말)")), "\n"), "\n", React.createElement(_components.h3, null, "Apple M1 칩"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "[docker for mac 프로젝트의 M1 칩 관련 이슈](https://github.com/docker/for-mac/issues/4733](https://github.com/docker/for-mac/issues/4733)"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
